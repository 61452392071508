.about-me-page {
    max-width: 1200px;
    margin: 0px;
}

section {
    margin-bottom: 40px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, color 0.3s;
}

body.dark section {
    background-color: var(--secondary-dark-bg-color);
    color: var(--secondary-dark-text-color);
}

.about-me-page h2 {
    font-size: 2.5em;
    text-align: center;
    margin: 0px;
    color: #333;
}

.about-content,
.hobbies-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.about-content img,
.hobbies-content img {
    width: 300px;
    height: auto;
    margin: 20px 0;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.university-logo,
.ukesf-logo,
.experience-logo {
    width: 150px;
    height: auto;
    margin: 10px;
}

.text-content {
    max-width: 800px;
    text-align: justify;
    margin-bottom: 20px;
}

.experience-section .experience-content {
    display: flex;
    flex-direction: column;
}

.experience-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.experience-item .experience-details {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.experience-details p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
    text-align: left;
}

.experience-details img {
    background-color: white;
}

ul {
    padding-left: 20px;
    font-size: 18px;
}

ul, ol {
    padding-left: 20px;
    font-size: 18px;
}

ul li, ol li {
    margin-bottom: 10px;
}

@media (min-width: 768px) {
    .about-content,
    .hobbies-content {
        flex-direction: row;
    }

    .about-content img,
    .hobbies-content img {
        margin: 0 20px;
    }

    .text-content {
        margin-left: 20px;
    }

    .experience-item .experience-details {
        flex-direction: row;
        justify-content: space-between;
    }

    .experience-item .experience-details img {
        margin-left: 20px;
    }
}
