@import url('variables.css');

.projects {
    padding: 20px;
    text-align: center;
    background-color: var(--light-bg-color);
    color: var(--light-text-color);
    transition: background-color 0.3s, color 0.3s;
}

.projects h2 {
    font-size: 2.5em;
    text-align: center;
    margin: 0px;
    color: #333;
}

body.dark .projects {
    background-color: var(--dark-bg-color);
    color: var(--dark-text-color);
}

.filter-controls {
    margin: 20px;
}

.filter-controls button {
    margin: 0 10px;
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background-color: var(--primary-color);
    color: white;
    border-radius: 5px;
    font-size: 16px;
}

.filter-controls button:hover {
    background-color: var(--primary-hover-color);
}

.projects-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.project-item {
    width: 300px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--secondary-bg-color);
    color: var(--secondary-text-color);
    box-shadow: var(--box-shadow);
    transition: background-color 0.3s, color 0.3s;
}

body.dark .project-item {
    border: 1px solid #555;
    background-color: var(--secondary-dark-bg-color);
    color: var(--secondary-dark-text-color);
}

.project-item img {
    width: 100%;
    height: 200px; /* Set a fixed height for the images */
    object-fit: contain; /* This will crop the images to fit within the specified dimensions */
    border-radius: 5px;
}

.project-item h3 {
    margin-top: 10px;
    margin-bottom: 0px;
}

.project-item p {
    flex-grow: 1;
}

.project-item a {
    margin-top: 10px;
    text-decoration: none;
    color: var(--primary-color);
}

.project-item a:hover {
    text-decoration: underline;
}

.project-item .read-more-btn {
    padding: 10px 20px;
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
}


@media (max-width: 768px) {
    .filter-controls button {
        margin-bottom: 10px;
    }
}