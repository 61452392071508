@import url('variables.css');

body {
    font-family: Arial, sans-serif;
    background-color: var(--light-bg-color);
    color: var(--light-text-color);
    transition: background-color 0.3s, color 0.3s;
}

body.dark {
    background-color: var(--dark-bg-color);
    color: var(--dark-text-color);
}

.contact-page {
    max-width: 1200px;
    margin: 0px;
    padding: 20px;
}

.contact-section {
    margin-bottom: 40px;
    padding: 20px;
    background-color: var(--secondary-bg-color);
    border-radius: 10px;
    box-shadow: var(--box-shadow);
}

body.dark .contact-section {
    background-color: var(--secondary-dark-bg-color);
}

.contact-page h2 {
    font-size: 2.5em;
    text-align: center;
    margin: 0px;
    color: var(--light-text-color);
}

body.dark h2 {
    color: var(--dark-text-color);
}

.contact-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact-info {
    max-width: 800px;
    text-align: center;
    margin-bottom: 20px;
}

.contact-info ul {
    list-style-type: none;
    padding: 0;
}

.contact-info ul li {
    margin-bottom: 10px;
}

.contact-info a {
    color: var(--primary-color);
    text-decoration: none;
}

.contact-info a:hover {
    text-decoration: underline;
}
