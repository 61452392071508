.project-details {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project-details h1 {
    text-align: center;
}

.project-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.project-item h3 {
    text-align: center;
    min-height: 88px;
}

.project-image {
    max-width: 500px;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
    flex-shrink: 0;
}

.project-description {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto; /* Ensures that description aligns from the top */
}

.project-description p {
    margin-bottom: 20px;
    font-size: 18px;
    text-align: justify;
}

.project-description h3, h4 {
    text-align: center;
}

.project-description table {
    margin-left: auto;
    margin-right: auto;
    border-spacing: 40px;
}

.project-description th {
    text-align: center;
}

body.light {
    --background-color: #f9f9f9;
    --text-color: #333;
}

body.dark {
    --background-color: #333;
    --text-color: #f9f9f9;
    --box-shadow-color: rgba(255, 255, 255, 0.1);
}

body.light .project-details {
    background-color: #fff;
    color: #333;
}

body.dark .project-details {
    background-color: #444;
    color: #f9f9f9;
}

.project-description img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    margin: 20px 0;
}

@media (max-width: 768px) {
    .project-image {
        width: 100%;
    }
}
